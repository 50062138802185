import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/MDXPageLayout.js";
import PageBanner from '../../components/PageBanner';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageBanner styleId={4} title={`Les règles de l'Hackaviz`} description={`Tout ce qu'il faut savoir pour bien participer au concours.`} mdxType="PageBanner" />
    <section id="one">
      <div className="inner">
        <h1>{`Les règles`}</h1>
        <h2>{`Inscription`}</h2>
        <ul>
          <li parentName="ul">{`Les candidats peuvent être des individus ou des équipes. Le nombre de personnes regroupées sous une candidature doit être précisé à l’inscription.
Une fois inscrit, le candidat (ou l’équipe) s’engage à déposer sa contribution avant la date de clôture.`}</li>
          <li parentName="ul">{`Les candidats s’engagent à autoriser la publication de leur contribution sur le site `}<a parentName="li" {...{
              "href": "https://toulouse-dataviz.fr"
            }}>{`toulouse-dataviz.fr`}</a>{` et pour une durée minimum de un an pour les contributions hébergées.`}</li>
          <li parentName="ul">{`Les candidats s’engagent à être présents ou représentés ou à défaut disponibles par vidéo lors de l’Hackaviz Party.`}</li>
        </ul>
        <h2>{`Données`}</h2>
        <ul>
          <li parentName="ul">{`Les participants n’ont pas l’obligation d’utiliser toutes les données.`}</li>
          <li parentName="ul">{`Hormis des fonds de carte, `}<strong parentName="li">{`les candidats ne sont pas autorisés à utiliser d’autres données que celles fournies`}</strong>{`.`}</li>
          <li parentName="ul">{`Les candidats peuvent effectuer tous types de calcul à partir de ce jeu de données.`}</li>
          <li parentName="ul">{`Les candidats peuvent utiliser tous les outils qu’ils souhaitent pour explorer les données du concours et en proposer une représentation visuelle à base de graphiques.`}</li>
        </ul>
        <h2>{`Contributions`}</h2>
        <ul>
          <li parentName="ul">{`Les candidats fourniront la liste des outils utilisés pour la réalisation des graphiques.`}</li>
          <li parentName="ul">{`Les candidats déposerons leur contribution avant la date limite selon les modalités qui leur seront communiquées.`}</li>
          <li parentName="ul">{`Le format de restitution d'une analyse visuelle `}<em parentName="li">{`statique`}</em>{` sera au `}<strong parentName="li">{`format pdf`}</strong>{`.`}</li>
          <li parentName="ul">{`Dans le cas d’une création interactive ou utilisant des services web, la soumission contiendra l'URL qui `}<strong parentName="li">{`ne devra pas dépasser l'équivalent de 2 pages A4 ou l'équivalent de 3 captures d'écran`}</strong>{` `}</li>
        </ul>
        <h1>{`Evaluation`}</h1>
        <p>{`Les contributions seront jugées par un jury de spécialistes proposé par les organisateurs. Ces personnalités n’ont pas le droit de concourir à l’Hackaviz, ni de collaborer avec aucune des équipes en compétition.`}</p>
        <p>{`Les contributions seront évaluées en fonction de différents critères dont les suivants (liste non exhaustive et non ordonnée par importance).`}</p>
        <ul>
          <li parentName="ul">{`Pertinence pour servir la narration/histoire abordée.`}</li>
          <li parentName="ul">{`Originalité`}</li>
          <li parentName="ul">{`Maîtrise des règles de l’art`}</li>
          <li parentName="ul">{`Concision`}</li>
        </ul>
        <p>{`Le jury de l’Hackaviz est souverain, ses décisions sont donc définitives. Le jury se réserve le droit de pénaliser les contributions qui ne respecteraient pas les règles. Les résultats seront annoncés lors de l’Hackaviz Party.`}</p>
        <h1>{`Hackaviz party`}</h1>
        <ul>
          <li parentName="ul">{`Avant la cérémonie de remise des prix, tous les participants pourront présenter leur réalisation et échanger.`}</li>
          <li parentName="ul">{`Tous les candidats pourront être appelés à présenter et commenter leur réalisation lors de la remise des prix.`}</li>
          <li parentName="ul">{`L’Hackaviz Party sera filmée en direct pour permettre aux participants ne pouvant se déplacer de voir l’événement à distance.`}</li>
        </ul>
        <h1>{`Conditions générales d’utilisation des données et des visualisations`}</h1>
        <ul>
          <li parentName="ul">
            <p parentName="li">{`Le jeu de données du concours est proposé par l’association Toulouse DataViz dans le cadre du concours Hackaviz afin de promouvoir la visualisation de données. Ce jeu de données est utilisé à des fins
pédagogiques en permettant aux participants de créer leurs visualisations.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Les données sources proviennent d’organisations officielles qui les publient sur Internet sans licence connue. Ces données, énumérées dans la section précédente, ont été traitées par l’équipe de
l’association Toulouse Dataviz pour enrichir leur contenu informationnel. L’association Toulouse Dataviz ne saurait garantir l’exactitude, la complétude et l’actualité ni des jeux de données
sources ni des traitements effectués sur ces données.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Les visualisations produites à l’issue de ce concours en temps limité n’engagent pas la responsabilité de l’association Toulouse Dataviz.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Le jeu de données est à l’usage exclusif de l’Hackaviz. Pour toutes autres utilisations, veuillez contacter l’association Toulouse Dataviz.`}</p>
          </li>
        </ul>
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      